<template>
  <div class="mt-10 p-3">
    <p class="text-xl text-center font-bold mb-16">تشغيل خدمات مجيب</p>
    <div class="action-field rtl mb-0">
      <div class="m-field-label">
        <label class="label">خدمة المتجر</label>
        <div class="m-tooltip">
          <img
            class="big-tabs__help-icon"
            src="../../assets/svg/icon.svg"
            alt="Info icon"
            width="15"
            height="15"
          />
          <span class="tooltip-content"
            >إن إيقاف حدمة المتجر الآلي لا تعني حذف جميع المنتجات بل فقط عدم إظهارها
            للمستخدمين</span
          >
        </div>
      </div>
      <div class="m-field-input">
        <button class="mujeeb-button big" @click="changeChatbotPublishState">
          <spring-spinner v-if="loading" :animation-duration="3000" :size="30" color="#000" />
          {{
            !loading
              ? $store.getters["page/chatbotSettings"] &&
                $store.getters["page/chatbotSettings"].published
                ? " إيقاف "
                : " تفعيل "
              : " "
          }}
        </button>
      </div>
    </div>
    <p class="text-right mb-10">هذه الخدمة مقدمة من قبل المجيب</p>

    <hr style="color:black; height: 1px; margin-bottom: 30px;" />

    <!-- <div class="action-field rtl mb-0">
      <div class="m-field-label">
        <label class="label">خدمة التوصيل</label>
        <div class="m-tooltip">
          <img
            class="big-tabs__help-icon"
            src="../../assets/svg/icon.svg"
            alt="Info icon"
            width="15"
            height="15"
          />
          <span class="tooltip-content"
            >إن إيقاف حدمة المتجر الآلي لا تعني حذف جميع المنتجات بل فقط عدم إظهارها
            للمستخدمين</span
          >
        </div>
      </div>
      <div class="m-field-input">
        <button class="mujeeb-button big" @click="enalbeDelivering">
          {{ $store.getters["page/deliveringIsActivated"] ? "إيقاف" : "تفعيل" }}
        </button>
      </div>
    </div>
    <p class="text-right mb-10">هذه الخدمة مقدمة من قبل المجيب</p> -->
  </div>
</template>

<script>
import SpringSpinner from "./../loading/SpringSpinner";
export default {
  components: {
    SpringSpinner
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    changeChatbotPublishState() {
      this.loading = true;
      this.$api
        .customRequest({
          method: "put",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/settings/published`,
          responseType: "json",
          data: { published: !this.$store.getters["page/chatbotSettings"].published }
        })
        .then(() => {
          return this.$store.dispatch("page/updateService", {
            servicename: "chatbot",
            change: { published: !this.$store.getters["page/chatbotSettings"].published }
          });
        })
        .finally(() => {
          this.loading = false;
          console.log("finished updating the published state");
        });
    },
    enalbeDelivering() {
      if (this.$store.getters["page/services"].delivering != null) {
        // re-enable
        console.log("re-enable");
      } else {
        // init
        console.log("init");
        this.$router.push({ name: "chatbot.delivery.initialization" });
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
