<template>
  <div style="position: realtive; margin-bottom: 100px;">
    <loading-spinner position="absolute" v-if="loading"></loading-spinner>
    <div v-else>
      <div v-for="(group, index) in botSettings.groups" :key="index" class="settings-container">
        <p v-if="group.title" class="mini-section" style="text-align: center;">{{ group.title }}</p>

        <inputField
          v-for="(property_, index) in botSettings.groupedInputs[group.name]"
          :data-v-step="index"
          v-if="property_.optional == null"
          :key="index"
          :inputFor="property_.inputFor"
          :inputType="property_.type"
          :optionsUrl="property_.optionsUrl"
          :title="property_.title"
          :inputIsRequired="property_.isRequired"
          :inputIsInteger="property_.isInteger"
          :maxlength="property_.maxlength"
          :value="settings[property_.name]"
          v-model="settings[property_.name]"
        >
        </inputField>

        <optionalInput
          v-for="(property_, index) in botSettings.groupedInputs[group.name]"
          v-if="property_.optional"
          :data-v-step="index"
          :key="index"
          :name="property_.name"
          :property="property_"
          :enabled="!!settings[property_.name]"
          @usageState="
            payload => {
              settings[payload.name] = payload.state ? settings[payload.name] : null;
            }
          "
          :header="property_.header"
        >
          <template v-slot:body>
            <dateInptu
              v-if="property_.type === 'date'"
              :title="property_.title"
              :value="settings[property_.name]"
              v-model="settings[property_.name]"
            >
            </dateInptu>

            <inputField
              v-else
              :key="index"
              :inputType="property_.type"
              :title="property_.title"
              :optionsUrl="property_.optionsUrl"
              :inputIsRequired="property_.isRequired"
              :inputIsInteger="property_.isInteger"
              :value="settings[property_.name]"
              v-model="settings[property_.name]"
            >
            </inputField>
          </template>
        </optionalInput>

        <hr />
      </div>

      <div class="mt-12">
        <p class="text-xl text-center font-semibold">بطاقة الترحيب</p>
        <p class="text-center mb-6 mt-2">البطاقة التي تظهر عند بدء المحادثة مع المستخدم</p>
        <div class="flex justify-center items-center content-center">
          <listTemplate
            :image="settings.image"
            :startText="settings.mainMenuButtons.startButton"
            :contactText="settings.mainMenuButtons.contactUsButton"
            @START_BUTTON_CHANGED="$v.settings.$model.mainMenuButtons.startButton = $event"
            @CONTACT_BUTTON_CHANGED="$v.settings.$model.mainMenuButtons.contactUsButton = $event"
            @IMAGE_CHANGED="$v.settings.$model.image = $event"
          ></listTemplate>
        </div>
      </div>

      <div class="settings-control">
        <button @click="submitSettings" class="mujeeb-button green big">تحديث</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("recipesConfig");
import optionalInput from "../input/OptionalInput";
import { EventBus } from "../../utils/EventBus";
import inputField from "../input/GenericInputField";
import listTemplate from "../chatbot/utilityComponents/listTemplate";

const isInteger = condition => value => {
  return condition ? /^\d+$/.test(value) || /[\u0660-\u0669]/g.test(value) : true;
};

const isRequired = (condition, inputType) => value => {
  return condition ? (inputType === "tags" ? value.length !== 0 : value !== "") : true;
};

export default {
  components: {
    inputField,
    optionalInput,
    listTemplate
  },
  data() {
    const contatButtonExists = this.$getAtPath(
      ["contact", "initiated"],
      this.$store.getters["page/services"]
    );
    const startButton = this.$store.getters["recipesConfig/welcomeCard"].buttons[0];
    const mainMenuButtons = { startButton: startButton };
    if (contatButtonExists) {
      mainMenuButtons.contactUsButton = "تواصل معنا";
    }
    return {
      pageId: null,
      loading: false,
      settings: {
        image: "",
        mainMenuButtons: mainMenuButtons
      },
      saved: false
    };
  },
  computed: {
    ...mapGetters(["botSettings"])
  },
  methods: {
    submitSettings() {
      EventBus.$emit("SUBMIT_ITEM");
      if (this.$v.$invalid) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يرجى إدخال المعلومات الناقصة",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        this.loading = true;
        this.$api
          .customRequest({
            method: "put",
            url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/settings`,
            responseType: "json",
            data: { ...this.finalSettings(), recipe: this.$store.getters["page/botRecipe"] }
          })
          .then(({ data }) => {
            this.$store.dispatch("page/changeChatbotSettings", this.finalSettings());
            this.$buefy.toast.open({
              duration: 3000,
              message: "تم تحديث الإعدادات",
              position: "is-top",
              type: "is-success"
            });
          })
          .catch(err => {
            console.log(err);
            this.$buefy.toast.open({
              duration: 3000,
              message: "حدث خطأ أثناء إدخال الإعدادات",
              position: "is-top",
              type: "is-danger"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    finalSettings() {
      const tempSettings = {};
      for (const property in this.settings) {
        switch (typeof this.settings[property]) {
          case "boolean":
            if (this.settings[property] != null) {
              tempSettings[property] = this.settings[property];
            }
            break;
          case "string":
            if (this.settings[property] != null && this.settings[property]) {
              tempSettings[property] = this.settings[property];
            } else {
              tempSettings[property] = null;
            }
            break;
          case "object":
            if (Array.isArray(this.settings[property])) {
              if (this.settings[property].length !== 0) {
                tempSettings[property] = this.settings[property];
              }
            } else {
              if (this.settings[property] == null) {
                tempSettings[property] = null;
              } else if (Object.keys(this.settings[property]).length !== 0) {
                tempSettings[property] = this.settings[property];
              }
            }
            break;
          case "number":
            if (this.settings[property] != null && this.settings[property]) {
              tempSettings[property] = this.settings[property];
            } else {
              tempSettings[property] = null;
            }
            break;
        }
      }
      return tempSettings;
    }
  },
  created() {
    // this.pageId = this.$getAtPath(["params", "pageId"], this.$route);
    // const settings = this.botSettings.inputs.reduce((acc, property) => {
    //   switch (property.type) {
    //     case "tags":
    //     case "multi-select":
    //       acc[property.name] = property.default != null ? property.default : [];
    //       break;
    //     case "switch":
    //       acc[property.name] = property.default != null ? property.default : true;
    //       break;
    //     case "select":
    //       acc[property.name] = property.default != null ? property.default : "";
    //       break;
    //     default:
    //       console.log("default", property.name);
    //       acc[property.name] = property.default != null ? property.default : null;
    //       break;
    //   }
    //   return acc;
    // }, {});
    // if (this.pageId != null) {
    //   this.loading = true;
    //   this.$api
    //     .customRequest({
    //       method: "get",
    //       url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/settings`,
    //       responseType: "json"
    //     })
    //     .then(({ data }) => {
    //       this.settings = Object.assign(this.settings, Object.assign(settings, data.settings));
    //       this.$store.dispatch("page/changeChatbotSettings", this.finalSettings());
    //       if (this.settings.image == null || this.settings.image == "") {
    //         this.settings.image = this.$store.getters["page/picture"];
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // } else {
    //   this.settings = Object.assign(settings, this.settings);
    // }
    this.settings = this.$store.getters["page/chatbotSettings"];
  },
  beforeRouteUpdate(to, from, next) {
    const pageId = this.$getAtPath(["params", "pageId"], to);
    if (pageId) {
      this.pageId = pageId;
    } else {
      this.pageId = null;
    }
    next();
  },
  validations() {
    let settings = this.botSettings.inputs.reduce((acc, property) => {
      acc[property.name] = {
        required: property.isRequired
          ? isRequired(true, this[property.name])
          : property.optional
          ? isRequired(this.settings[property.name] != null, this[property.name])
          : isRequired(!!property.isRequired, this[property.name]),
        integer: property.optional
          ? isInteger(this.settings[property.name] != null && property.isInteger)
          : isInteger(!!property.isInteger)
      };
      return acc;
    }, {});

    settings = {
      ...settings,
      image: { required: isRequired(true, "string") },
      mainMenuButtons: {
        contactUsButton: { required: isRequired(true, "string") },
        startButton: { required: isRequired(true, "string") }
      }
    };

    // add the new buttons based on constact-us
    return { settings };
  }
};
</script>

<style scoped lang="scss">
.mini-section {
  text-align: center;
  font-size: 20px;
  margin: 50px auto;
  font-weight: 600;
}
.settings-control {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 70px;
}
</style>
